import React from "react";
import { Frame5Wrapper } from "../Home/VectorConent";
import "../../styles/global.css";
import Infrastucture from "../../Public/about_us/Infrastructure.svg";
import "../../styles/aboutUs/about-us-content.css";

const StarComponent = ({ value }) => {
  const starts = [0, 0, 0, 0, 0];
  for (let each = 0; each < value; each++) {
    starts[each] = 1;
  }
  return (
    <div className="star_container">
      {starts.map((each, index) =>
        each === 1 ? (
          <span key={index} className="fa fa-star checked"></span>
        ) : (
          <span key={index} className="fa fa-star"></span>
        )
      )}
    </div>
  );
};

const AboutUsContent = () => {
  return (
    <React.Fragment>
      <div className="container-fluid blog-3">
        <div className="container pt-3">
          <div className="d-flex col-12 p-3">
            <div className="col-5">
              <img className="img-java" src={Infrastucture} alt="Infrastucture" />
            </div>
            <div className="col-7">
              <h1 className="heading">Our Infrastructure</h1>
              <p className="p-tag">Explore an exceptional learning ambiance at neoteric METHOD, featuring state-of-the-art infrastructure. Our meticulously crafted facilities and cutting-edge technology provide an ideal environment for mastering software skills. Committed to modern amenities, we guarantee an inspirational space equipped with advanced teaching methods and tools, fostering knowledge and laying the groundwork for success in the dynamic digital landscape.</p>
            </div>
          </div>
        </div>
      </div>
    


      {/* <div className="about_us_container_fsas">
        <div className="about-us-sub-container col-md-2 col-sm-4 col-12">
          <img src={Infrastucture}
            alt="infrastructure"
            style={{
              width: 350,
              height: 350,
            }}
          />
          <div
            style={{
              flex: "1 1 0",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#1A2F4C",
                fontSize: 40,
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Our Infrastructure
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "black",
                fontSize: 16,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Explore an exceptional learning ambiance at neoteric METHOD,
              featuring state-of-the-art infrastructure. Our meticulously
              crafted facilities and cutting-edge technology provide an ideal
              environment for mastering software skills. Committed to modern
              amenities, we guarantee an inspirational space equipped with
              advanced teaching methods and tools, fostering knowledge and
              laying the groundwork for success in the dynamic digital
              landscape.
            </div>
          </div>
        </div>
      </div> */}

      <Frame5Wrapper>

      <div className="container-fluid">
        <div className="container pt-3 ">
          <div className="col p-3">            
            <h1 className="heading-1  mb-0 pb-0">Discover what our students are saying </h1>
            <h1 className="heading-1  mb-0 pb-0 pt-0 mt-0">about us and our institute.</h1>
          </div>
            <div className="text-align-center col-12">
              <p className="p-tag-1">We provide top-notch infrastructure to shape your career, caring deeply about your aspirations to become a successful engineer in life.</p>
            
          </div>
        </div>
      </div>
      <div className="container mb-3">
        <div className="row justify-content-center pt-3">
          <div className="col-lg-5 col-md-6 col-sm-12 card p-2 m-3"> 
            <div><StarComponent value={5} /></div>
            <p className="row m-1">Hi! I am Kanaka Durga, I am delighted to share my exceptional experience with neoteric METHOD. Their comprehensive Java training program surpassed my expectations. The state-of-the-art infrastructure, combined with engaging teaching methodologies, created an enriching learning environment. neoteric METHOD&quots unwavering commitment to fostering innovation and cultivating skills unequivocally positions them as a leader in the industry.</p>
            <h6 className="review">-Kanaka Durga</h6>
          </div>   
          <div className="col-lg-5 col-md-6 col-sm-12 card p-2 m-3"> 
            <div><StarComponent value={5} /></div>
            <p className="row m-1">Hi! Im Gopichand, Im serving as the Code Architect at Neoteric Methods. Our management ensures an exceptional environment and delivers top-notch lectures to nurture aspiring coding architects. We offer hands-on coding experience and facilitate live interactions with clients. neoteric METHOD stands as the premier software training program for individuals aspiring to become exceptional software developers.</p>
            <h6 className="review">-Gopichand</h6>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 card p-2 m-3"> 
            <div><StarComponent value={5} /></div>
            <p className="row m-1">Hi! Im Sasank, I enrolled in the Java Full stack course at neoteric METHOD, and the experience has been reminiscent of an engaging research and development (R&D) journey. The trainer is a comprehensive technologist proficient in Java and various tools. Im confident in recommending neoteric METHOD to my friends and relatives who aspire to become skilled engineers.</p>
            <h6 className="review">-Sasank</h6>
          </div>         
        </div>
      </div>

        {/* <div
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 16,
            display: "inline-flex",
          }}
        >
          <div
            style={{
              width: 804,
              textAlign: "center",
              color: "#1A2F4C",
              fontSize: 40,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            Discover what our students are saying about us and our institute.
          </div>
          <div
            style={{
              width: 858,
              textAlign: "center",
              color: "black",
              fontSize: 20,
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            We provide top-notch infrastructure to shape your career, caring
            deeply about your aspirations to become a successful engineer in
            life.
          </div>
        </div> */}

        {/* <div className="review-container">
          <div
            style={{
              width: 550,
              height: 306,
              padding: 24,
              background: "white",
              borderRadius: 16,
              border: "2px #DBDADF solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 16,
              display: "inline-flex",
              position: "relative",
            }}
          >
            <StarComponent value={5} />
            <div
              style={{
                alignSelf: "stretch",
                color: "#3F3F46",
                fontSize: 16,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Hi! I am Kanaka Durga, I am delighted to share my exceptional
              experience with neoteric METHOD. Their comprehensive Java training
              program surpassed my expectations. The state-of-the-art
              infrastructure, combined with engaging teaching methodologies,
              created an enriching learning environment. neoteric METHOD&quots
              unwavering commitment to fostering innovation and cultivating
              skills unequivocally positions them as a leader in the industry.
            </div>
            <div
              style={{
                color: "#18181B",
                fontSize: 16,
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              -Kanaka Durga
            </div>
          </div>
          <div
            style={{
              width: 550,
              height: 306,
              padding: 24,
              borderRadius: 16,
              border: "2px #DBDADF solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 16,
              display: "inline-flex",
              position: "relative",
            }}
          >
            <StarComponent value={5} />
            <div
              style={{
                alignSelf: "stretch",
                color: "#3F3F46",
                fontSize: 16,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Hi! Im Gopichand, Im serving as the Code Architect at Neoteric
              Methods. Our management ensures an exceptional environment and
              delivers top-notch lectures to nurture aspiring coding architects.
              We offer hands-on coding experience and facilitate live
              interactions with clients. neoteric METHOD stands as the premier
              software training program for individuals aspiring to become
              exceptional software developers
            </div>
            <div
              style={{
                color: "#18181B",
                fontSize: 16,
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              -Gopichand
            </div>
          </div>
          <div
            style={{
              width: 550,
              height: 306,
              padding: 24,
              borderRadius: 16,
              border: "2px #DBDADF solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 16,
              display: "inline-flex",
              position: "relative",
            }}
          >
            <StarComponent value={5} />
            <div
              style={{
                alignSelf: "stretch",
                color: "#3F3F46",
                fontSize: 16,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Hi! Im Shashank, I enrolled in the Java Full stack course at
              neoteric METHOD, and the experience has been reminiscent of an
              engaging research and development (R&D) journey. The trainer is a
              comprehensive technologist proficient in Java and various tools.
              Im confident in recommending neoteric METHOD to my friends and
              relatives who aspire to become skilled engineers.
            </div>
            <div
              style={{
                color: "#18181B",
                fontSize: 16,
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              -Shashank
            </div>
          </div>
        </div> */}

        {/* <div className="review-container">
          <div className="review-card">
            <StarComponent value={5} />
            <div className="review-content">
              Hi! I am Kanaka Durga, I am delighted to share my exceptional
              experience with neoteric METHOD. Their comprehensive Java training
              program surpassed my expectations. The state-of-the-art
              infrastructure, combined with engaging teaching methodologies,
              created an enriching learning environment. neoteric METHOD&quots
              unwavering commitment to fostering innovation and cultivating
              skills unequivocally positions them as a leader in the industry.
              <div
                style={{
                  color: "#18181B",
                  fontSize: 16,
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                {" "}
                -Kanaka Durga
              </div>
            </div>
          </div>
          <div className="review-card">
            <StarComponent value={5} />
            <div className="review-content">
              Hi! Im Gopichand, Im serving as the Code Architect at Neoteric
              Methods. Our management ensures an exceptional environment and
              delivers top-notch lectures to nurture aspiring coding architects.
              We offer hands-on coding experience and facilitate live
              interactions with clients. neoteric METHOD stands as the premier
              software training program for individuals aspiring to become
              exceptional software developers
            </div>
            <div
              style={{
                color: "#18181B",
                fontSize: 16,
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              -Gopichand
            </div>
          </div>

          <div className="review-card">
            <StarComponent value={5} />

            <div className="review-content">
              Hi! Im Shashank, I enrolled in the Java Full stack course at
              neoteric METHOD, and the experience has been reminiscent of an
              engaging research and development (R&D) journey. The trainer is a
              comprehensive technologist proficient in Java and various tools.
              Im confident in recommending neoteric METHOD to my friends and
              relatives who aspire to become skilled engineers.
            </div>
            <div
              style={{
                color: "#18181B",
                fontSize: 16,
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              -Sasank
            </div>
          </div>
        </div> */}
      </Frame5Wrapper>
    </React.Fragment>
  );
};

export default AboutUsContent;
