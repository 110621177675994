import { atom, selector } from "recoil";

export const registerAtom = atom({
  key: "register",
  default: {
    show: false,
    email: { value: "", error: "" },
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    mobile: { value: "", error: "" },
    message: "",
    success: false,
  },
});

export const initialFormValues = {
  show: false,
  email: { value: "", error: "" },
  firstName: { value: "", error: "" },
  lastName: { value: "", error: "" },
  mobile: { value: "", error: "" },
  message: "",
  success: false,
};

export const registerSelector = selector({
  key: "registerSelector",
  get: ({ get }) => {
    const registerData = get(registerAtom);
    return registerData;
  },
  set: ({ set }, newRegisterValue) => {
    set(registerAtom, newRegisterValue);
  },
});

export const contactUsAtom = atom({
  key: "contactUs",
  default: {
    show: false,
    email: { value: "", error: "" },
    mobile: { value: "", error: "" },
    fullName: { value: "", error: "" },
    remarks: { value: "", error: "" },
    message: "",
    success: false,
  },
});

export const initialContactUsForm = {
  show: false,
  email: { value: "", error: "" },
  mobile: { value: "", error: "" },
  fullName: { value: "", error: "" },
  remarks: { value: "", error: "" },
  message: "",
  success: false,
};

export const contactUsSelector = selector({
  key: "contactUsSelector",
  get: ({ get }) => {
    const contactUsData = get(contactUsAtom);
    return contactUsData;
  },
  set: ({ set }, newContactUsValue) => {
    set(contactUsAtom, newContactUsValue);
  },
});
