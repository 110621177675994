import LadyWithHeadset from "../../Public/contact_us/LadyWithHeadSet.svg";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { contactUsAtom, initialContactUsForm } from "../../recoil/register";
import { nameRegex } from "../../App";
import { mailCofig } from "../../emailconfig/nodemailer";
import "../../styles/Navbar/nav-contactus.css";
import { sendConnectionContactUs } from "../../utils/mailerApi";

const ContactUsForm = () => {
  const [inProgress, setInProgress] = useState({ message: "", status: false });
  const [registerForm, setRegisterForm] = useRecoilState(contactUsAtom);

  const handleRegisterForm = (e) => {
    const { name, value, id } = e.target;
    let validateValue = value;

    if (id === "name" && validateValue?.length > 50) return;

    if (
      id === "name" &&
      name !== "email" &&
      validateValue &&
      !nameRegex.test(validateValue)
    )
      return;

    if (id === "number" && parseInt(validateValue) < 10000000000) {
      validateValue = parseInt(validateValue) || "";
    } else if (id === "number" && parseInt(validateValue) > 10000000000) {
      return;
    }

    setRegisterForm({
      ...registerForm,
      [name]: { value: validateValue, error: validateValue ? "" : name === "remarks" ? "" : "Required" },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProgress(true);
    let validateValues = { ...registerForm };
    let formValid = true;

    Object.keys(registerForm).forEach((each) => {
      if (each !== "show" && each !== "message" && each !== "success" && each != "remarks") {
        validateValues[each] = validateValues[each].value
          ? validateValues[each]
          : { ...validateValues[each], error: "Required" };

        if (formValid && !validateValues[each].value) {
          formValid = false;
        }
      }
    });

    if (formValid) {
      try {
        await sendConnectionContactUs(registerForm);
        setRegisterForm({
          ...registerForm,
          show: true,
          success: true,
          message:
            "We have received your request successfully. We will reach out to you.",
        });

        setTimeout(() => {
          setRegisterForm({ ...initialContactUsForm, show: false });
          setInProgress(false);
        }, 2000);
      } catch (e) {
        setInProgress(false);
        setRegisterForm({
          ...validateValues,
          message: "Please provide us valid credentials.",
          success: false,
        });
      }
    } else {
      setInProgress(false);
      setRegisterForm({ ...validateValues });
    }
  };

  return (

    // <div
    //   style={{
    //     width: "-webkit-fill-available",
    //     color: "black",
    //     fontSize: 32,
    //     fontWeight: "700",
    //     wordWrap: "break-word",
    //     display: "flex",
    //     justifyContent: "space-between",
    //     padding: "20px 100px",
    //   }}
    // >
    <div className="container-fluid">
      <div className="container col-12 pt-3">
        <h1 className="Contact-heading">Contact Us</h1>
        <p className="p-tag">Secure your spot today and unlock a world of opportunities by
          registering for our transformative programs</p>
      </div>

      {/* Form */}
      <div className="container mt-5">

        <form id="myForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="mb-3">
                {/* <div className="col-md-6 mb-3"> */}
                  <label for="fullName" className="form-label">Full Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullName"
                    id="name"
                    onChange={handleRegisterForm}
                    value={registerForm.fullName.value} 
                    placeholder="Enter your full name" required></input>
                  <Form.Text
                    className="text-danger m-2"
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {registerForm.fullName.error}
                  </Form.Text>
                {/* </div> */}
                {/* <div className="col-md-6 mb-3">
                  <label for="lastName" className="form-label">Last Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    
                    placeholder="Enter your last name"
                    required />
                </div> */}
              </div>
              <div className="mb-3">
                <label for="email" className="form-label">Email Address*</label>
                <input
                  type="email"
                  className="form-control"
                  id="name"
                  name="email"
                  onChange={handleRegisterForm}
                  value={registerForm.email.value}
                  placeholder="Enter your email address"
                  required
                />
                <Form.Text
                  className="text-danger m-2"
                  style={{
                    fontSize: "small",
                  }}
                >
                  {registerForm.email.error}
                </Form.Text>
              </div>
              <div className="mb-3">
                <label for="mobileNumber" className="form-label">Mobile Number*</label>
                <input
                  type="tel"
                  className="form-control"
                  name="mobile"
                  id="number"
                  onChange={handleRegisterForm}
                  value={registerForm.mobile.value}
                  placeholder="Enter your mobile number"
                />
                <Form.Text
                  className="text-danger m-2"
                  style={{
                    fontSize: "small",
                  }}
                >
                  {registerForm.mobile.error}
                </Form.Text>
              </div>
              <div className="mb-3">
                <label for="remarks" className="form-label">Remarks (Optional)</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter any remarks you may have"
                  name="remarks"
                  id="name"
                  onChange={handleRegisterForm}
                  value={registerForm.remarks.value}
                />
              </div>
              <button type="submit" className="btn btn-primary mb-3">Submit</button>

              <Form.Text
                className={`${registerForm?.success ? "text-success" : "text-danger"
                  } m-6`}
                style={{
                  fontSize: "small",
                }}
              >
                {registerForm?.message || ""}
              </Form.Text>
            </div>

            <div className="col-md-5 mb-3">
              <img className="contact-img"
                src={LadyWithHeadset}
                alt="Rectangle"
              />
            </div>
          </div>
        </form>



        {/* <div
          style={{
            color: "black",
            fontSize: 32,
            fontWeight: "700",
            wordWrap: "break-word",
          }}
        >
          Contact Us
        </div>
        <div
          style={{
            width: "-webkit-fill-available",
            color: "#5D5D5D",
            fontSize: 16,
            fontWeight: "500",
            wordWrap: "break-word",
          }}
        >
          Secure your spot today and unlock a world of opportunities by
          registering for our transformative programs
        </div> */}

        {/* <Form
          style={{
            width: "-webkit-fill-available",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 24,
            display: "inline-flex",
          }}
          onSubmit={handleSubmit}
        >
          <div
            style={{
              alignSelf: "stretch",
              height: 96,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <Form.Label
              style={{
                alignSelf: "stretch",
                height: 96,
                fontWeight: "600",
                fontSize: 16,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              Full Name*
            </Form.Label>
            <Form.Control
              type="text"
              style={{
                alignSelf: "stretch",
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 22,
                paddingBottom: 22,
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #DBDADF solid",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              placeholder={"Enter fullname"}
              name="fullName"
              id="name"
              onChange={handleRegisterForm}
              value={registerForm.fullName.value}
            />
            <Form.Text
              className="text-danger m-2"
              style={{
                fontSize: "small",
              }}
            >
              {registerForm.fullName.error}
            </Form.Text>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 96,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <Form.Label
              style={{
                alignSelf: "stretch",
                height: 96,
                fontWeight: "600",
                fontSize: 16,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              Email*
            </Form.Label>
            <Form.Control
              type="text"
              style={{
                alignSelf: "stretch",
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 22,
                paddingBottom: 22,
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #DBDADF solid",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              placeholder={"Enter your email"}
              name="email"
              id="name"
              onChange={handleRegisterForm}
              value={registerForm.email.value}
            />
            <Form.Text
              className="text-danger m-2"
              style={{
                fontSize: "small",
              }}
            >
              {registerForm.email.error}
            </Form.Text>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 96,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <Form.Label
              style={{
                alignSelf: "stretch",
                height: 96,
                fontWeight: "600",
                fontSize: 16,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              Mobile*
            </Form.Label>
            <Form.Control
              type="text"
              style={{
                alignSelf: "stretch",
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 22,
                paddingBottom: 22,
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #DBDADF solid",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              placeholder={"Enter mobile number"}
              name="mobile"
              id="number"
              onChange={handleRegisterForm}
              value={registerForm.mobile.value}
            />
            <Form.Text
              className="text-danger m-2"
              style={{
                fontSize: "small",
              }}
            >
              {registerForm.mobile.error}
            </Form.Text>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <Form.Label
              style={{
                alignSelf: "stretch",
                fontWeight: "600",
                fontSize: 16,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              Remarks*
            </Form.Label>
            <Form.Control
              as="textarea"
              style={{
                alignSelf: "stretch",
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
                paddingBottom: 22,
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #DBDADF solid",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              placeholder={"Enter remarks"}
              row={6}
              name="remarks"
              id="name"
              onChange={handleRegisterForm}
              value={registerForm.remarks.value}
            />
            <Form.Text
              style={{
                fontSize: "small",
              }}
              className="text-danger m-2"
            >
              {registerForm.remarks.error}
            </Form.Text>
          </div>

          <Button
            style={{
              color: "#141414",
              fontSize: 18,
              fontWeight: "500",
              wordWrap: "break-word",
              width: 238,
              height: 64,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 14,
              paddingBottom: 14,
              background: "#FFD11A",
              borderColor: "#FFD11A",
              borderRadius: 16,
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              display: "inline-flex",
            }}
            type="submit"
            disabled={inProgress}
          >
            Submit
          </Button>
        </Form>
        <Form.Text
          className={`${registerForm?.success ? "text-success" : "text-danger"
            } m-6`}
          style={{
            fontSize: "small",
          }}
        >
          {registerForm?.message || ""}
        </Form.Text> */}
      </div >

      {/* <div style={{ position: "relative", width: "-webkit-fill-available" }}>
        <div
          style={{
            height: 175,
            width: 175,
            right: 225,
            top: 220,
            position: "absolute",
            background: "#FFD119",
          }}
        />
        <div
          style={{
            right: 31,
            height: 175,
            width: 175,
            top: 38,
            position: "absolute",
            background: "#FFD119",
          }}
        />
        <img
          style={{
            right: 64,
            top: 64,
            position: "absolute",
            width: 300,
            height: 300,
          }}
          src={LadyWithHeadset}
          alt="Rectangle"
        />
      </div> */}
    </div >
  );
};

export default ContactUsForm;
