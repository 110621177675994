import React from "react";
import NavBar from "../../Components/Navbar";
const Banner = React.lazy(() => import("../../Components/Home/Banner"));
const Others = React.lazy(() => import("../../Components/Home/Others"));
const FooterNeo = React.lazy(() => import("../../Components/FooterNeo"));
const Course = React.lazy(() => import("../../Components/Home/Courses"));

const Home = () => {
  return (
    <>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}
      <NavBar pageName={"Home"} />
      <Banner />
      <Others />
      <Course />
      <div>
        <FooterNeo />
      </div>
    </>
  );
};

export default Home;
