import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const RegisterForm = ({
  registerForm,
  handleClose,
  handleRegisterForm,
  handleSubmit,
  inProgress,
}) => {
  return (
    <Modal show={registerForm?.show} onHide={handleClose} size="lg" id="md1">
      <Modal.Header closeButton>
        <div>
          <div className="font-weight-400 f16 p-6">
            <div
              style={{
                color: "black",
                fontSize: 24,
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Register
            </div>
          </div>
          {/* <div
            style={{
              width: "-webkit-fill-available",
              color: "#5D5D5D",
              fontSize: 16,
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            Secure your spot today and unlock a world of opportunities by
            registering for our transformative programs
          </div> */}
        </div>
      </Modal.Header>
      <Modal.Body className="px-4 mx-5 pb-5 pt-3">
        <Form>
          <Row>
            <Col xs={6} className="px-2">
              <Form.Text
                className={`${
                  registerForm?.success ? "text-success" : "text-danger"
                }`}
              >
                {registerForm?.message || ""}
              </Form.Text>
            </Col>
          </Row>
          <Row className=" mt-4 mb-4">
            <Col xs={12} sm={6}  className="px-2">
              <Form.Label
                style={{
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                First Name*
              </Form.Label>
              <Form.Control
                onChange={handleRegisterForm}
                type="text"
                name="firstName"
                id="name"
                style={{
                  padding: "16px 12px",
                  borderRadius: 16,
                }}
                placeholder={"Enter first name"}
                value={registerForm?.firstName?.value || ""}
              />
              <Form.Text className="text-danger m-2">
                {registerForm?.firstName?.error}
              </Form.Text>
            </Col>
            <Col xs={12} sm={6} className="px-2">
              <Form.Label
                style={{
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                Last Name*
              </Form.Label>
              <Form.Control
                onChange={handleRegisterForm}
                id="name"
                type="text"
                name="lastName"
                style={{
                  padding: "16px 12px",
                  borderRadius: 16,
                }}
                placeholder={"Enter last name"}
                value={registerForm?.lastName?.value || ""}
              />
              <Form.Text className="text-danger m-2">
                {registerForm?.lastName?.error}
              </Form.Text>
            </Col>
          </Row>
          <Row className=" mt-4 mb-4">
            <Col xs={12} sm={6} className="px-2">
              <Form.Label
                style={{
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                Email*
              </Form.Label>
              <Form.Control
                onChange={handleRegisterForm}
                type="text"
                name="email"
                id="name"
                style={{
                  padding: "16px 12px",
                  borderRadius: 16,
                }}
                placeholder={"Enter email"}
                value={registerForm?.email?.value || ""}
              />
              <Form.Text className="text-danger m-2">
                {registerForm?.email?.error}
              </Form.Text>
            </Col>
            <Col xs={12} sm={6} className="px-2">
              <Form.Label
                style={{
                  fontWeight: "600",
                  fontSize: 16,
                }}
              >
                Mobile Number*
              </Form.Label>
              <Form.Control
                id="number"
                onChange={handleRegisterForm}
                type="text"
                name="mobile"
                style={{
                  padding: "16px 12px",
                  borderRadius: 16,
                }}
                placeholder={"Enter mobile number"}
                value={registerForm?.mobile?.value || ""}
              />
              <Form.Text className="text-danger m-2">
                {registerForm?.mobile?.error}
              </Form.Text>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            color: "#5D5D5D",
            fontSize: 18,
            fontWeight: "500",
            wordWrap: "break-word",
            width: 200,
            height: 50,
            padding: "12px 8px",
            background: "#FFF",
            border: "1px #E0E0E0 solid",
            borderRadius: 16,
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
            display: "inline-flex",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          style={{
            color: "#141414",
            fontSize: 18,
            fontWeight: "500",
            wordWrap: "break-word",
            width: 200,
            height: 50,
            padding: "12px 8px",
            background: "#FFD11A",
            borderColor: "#FFD11A",
            borderRadius: 16,
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
            display: "inline-flex",
          }}
          onClick={handleSubmit}
          inProgress={inProgress}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Register = ({
  registerForm,
  handleClose,
  handleRegisterForm,
  handleSubmit,
  inProgress,
}) => {
  return (
    <RegisterForm
      registerForm={registerForm}
      handleClose={handleClose}
      handleRegisterForm={handleRegisterForm}
      handleSubmit={handleSubmit}
      inProgress={inProgress}
    />
  );
};

export default Register;
