import { Button } from "react-bootstrap";
import FooterNeo from "../../Components/FooterNeo";
import NavBar from "../../Components/Navbar";
import JavaImg from "../../Public/JavaImg.svg";
import Microservices from "../../Public/Microservices.svg";
import SpringBoot from "../../Public/SpringBoot.svg";
import Kubernetes from "../../Public/courses/Kubernetes.svg";
import { useRecoilState } from "recoil";
import { registerSelector } from "../../recoil/register";
import "../../styles/home/courses.css";
import bannerStyles from "../../styles/services/Banner.module.scss";

const Courses = () => {
  const [registerForm, setRegisterForm] = useRecoilState(registerSelector);

  const handleClose = () => {
    setRegisterForm({ ...registerForm, show: !registerForm.show });
  };


  const RegisterButton = () => {
    const [registerForm, setRegisterForm] = useRecoilState(registerSelector);

    const handleFunction = () => {
      setRegisterForm({ ...registerForm, show: !registerForm.show });
    };

    return <div className="courses-text">
      <div className="courses-bg">
        <Button
          className={bannerStyles.register_button}
          onClick={handleFunction}>
          Register
        </Button>
      </div>
    </div>
  }

  return (
    <>
      <NavBar pageName={"Courses"} />
      <div style={{ position: "relative" }}>
        <RegisterButton />
      </div>
      <div className="bg-image-Courses">
        <div className="blog-1 col-lg-6 col-md-6">
          <h1 className="blog-heading">Dive into Our Dynamic Course Offerings!</h1>
          <p className="blog-text">Explore a diverse range of cutting-edge courses tailored to
            elevate your skills and knowledge. From programming paradigms to
            advanced technologies, our courses are meticulously designed to
            empower you in the ever-evolving world of technology. Embark on a
            journey of continuous learning and discover the key to unlocking
            your full potential</p>
        </div>
      </div>

      <div className="blog-3">
        <div className="container">
          <div className="d-flex blog-3 col-12 p-2">
            <div className="col-6">
              <img className="img" src={JavaImg} alt="JavaImg" />
            </div>
            <div className="content col-6">
              <h1 className="heading">Java</h1>
              <p className="p-tag">Unlock the power of Java with our comprehensive course. From fundamental concepts to advanced techniques, delve into the world of Java programming and emerge with the skills to develop robust applications. Join us on a dynamic learning journey designed to propel your proficiency in Java development.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-4">
        <div className="container">
          <div className="d-flex col-12 p-2">
            <div className="content col-6">
              <h1 className="heading">Microservices</h1>
              <p className="p-tag">Dive into the world of Microservices with our specialized course. Explore the architecture, design principles, and implementation strategies behind microservices, empowering yourself to build scalable, resilient, and efficient applications. Join us to master the intricacies of modern software development through this dynamic and hands-on learning experience.</p>
            </div>
            <div className="col-6">
              <img className="img" src={Microservices} alt="Microservices" />
            </div>
          </div>
        </div>
      </div>

      <div className="blog-3">
        <div className="container">
          <div className="d-flex blog-3 col-12 p-2">
            <div className="col-6">
              <img className="img" src={SpringBoot} alt="SpringBoot" />
            </div>
            <div className="content col-6">
              <h1 className="heading">Spring Boot</h1>
              <p className="p-tag">Embark on a journey into modern Java development with our Spring Boot course. Discover the power of this framework for building robust and scalable applications effortlessly. From streamlined configuration to rapid development, gain hands-on experience and unlock the potential of Spring Boot to accelerate your projects and elevate your Java programming skills.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-4">
        <div className="container">
          <div className="d-flex col-12 p-2">
            <div className="content col-6">
              <h1 className="heading">Kubernetes</h1>
              <p className="p-tag">Discover the Power of Kubernetes in Our Institute: At neoteric METHOD, we embrace cutting-edge technology to enrich learning experiences. Our curriculum incorporates Kubernetes, the industry-leading container orchestration platform, empowering students to grasp modern cloud computing principles. Through hands-on projects and dedicated coursework, our learners dive into deploying, managing, and scaling applications using Kubernetes. Join us to explore the forefront of technology and prepare for the dynamic landscape of tomorrow.</p>
            </div>
            <div className="col-6">
              <img className="img" src={Kubernetes} alt="Kubernetes" />
            </div>
          </div>
        </div>
      </div>



      {/* <div
        style={{
          width: 100,
          height: 100,
          position: "fixed",
          alignSelf: "flex-end",
          top: 480,
          zIndex: 999999,
        }}
      >
        <div
          style={{
            width: 100,
            height: 100,
            left: 0,
            top: 0,
            position: "absolute",
            background: "#FFD119",
            borderRadius: 9999,
          }}
        />
        <Button
          style={{
            left: 6,
            top: 32,
            position: "absolute",
            color: "black",
            fontSize: 18,
            fontWeight: "500",
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={handleClose}
        >
          Register
        </Button>
      </div> */}


      {/* <div
        style={{
          width: "100%",
          height: "100%",
          padding: 100,
          background: "#F1F3F2",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 150,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 385,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 150,
            display: "flex",
          }}
        >
          <img
            style={{
              width: 386,
              height: 386,
              borderRadius: 24,
            }}
            src={JavaImg}
            alt="Java"
          />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "#1A2F4C",
                fontSize: 40,
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Java
            </div>

            <div
              style={{
                alignSelf: "stretch",
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "black",
                fontSize: 20,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Unlock the power of Java with our comprehensive course. From
              fundamental concepts to advanced techniques, delve into the world
              of Java programming and emerge with the skills to develop robust
              applications. Join us on a dynamic learning journey designed to
              propel your proficiency in Java development.
            </div> */}

      {/* <div
              style={{
                width: 238,
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 14,
                paddingBottom: 14,
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                background: "#FFD11A",
                borderRadius: 16,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#141414",
                  fontSize: 18,
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Explore Courses
              </div>
            </div> 
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 100,
          background: "white",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 150,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 385,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 150,
            display: "flex",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "#1A2F4C",
                fontSize: 40,
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Microservices
            </div>

            <div
              style={{
                alignSelf: "stretch",
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "black",
                fontSize: 20,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Dive into the world of Microservices with our specialized course.
              Explore the architecture, design principles, and implementation
              strategies behind microservices, empowering yourself to build
              scalable, resilient, and efficient applications. Join us to master
              the intricacies of modern software development through this
              dynamic and hands-on learning experience.
            </div>

            {/* <div
              style={{
                width: 238,
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 14,
                paddingBottom: 14,
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                background: "#FFD11A",
                borderRadius: 16,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#141414",
                  fontSize: 18,
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Explore Courses
              </div>
            </div> 
          </div>
          <img
            style={{ borderRadius: 24, width: 386, height: 386 }}
            src={Microservices}
            alt="Micro services"
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 100,
          background: "#F1F3F2",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 150,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 385,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 150,
            display: "flex",
          }}
        >
          <img width={385} height={385} src={SpringBoot} alt="Sprint boot" />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "#1A2F4C",
                fontSize: 40,
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Spring Boot
            </div>

            <div
              style={{
                alignSelf: "stretch",
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "black",
                fontSize: 20,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Embark on a journey into modern Java development with our Spring
              Boot course. Discover the power of this framework for building
              robust and scalable applications effortlessly. From streamlined
              configuration to rapid development, gain hands-on experience and
              unlock the potential of Spring Boot to accelerate your projects
              and elevate your Java programming skills.
            </div>

            {/* <div
              style={{
                width: 238,
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 14,
                paddingBottom: 14,
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                background: "#FFD11A",
                borderRadius: 16,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#141414",
                  fontSize: 18,
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Explore Courses
              </div>
            </div> 

          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 100,
          background: "white",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 150,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 385,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 150,
            display: "flex",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "#1A2F4C",
                fontSize: 40,
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Kubernetes
            </div>

            <div
              style={{
                alignSelf: "stretch",
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                color: "black",
                fontSize: 20,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Discover the Power of Kubernetes in Our Institute: At neoteric
              METHOD, we embrace cutting-edge technology to enrich learning
              experiences. Our curriculum incorporates Kubernetes, the
              industry-leading container orchestration platform, empowering
              students to grasp modern cloud computing principles. Through
              hands-on projects and dedicated coursework, our learners dive into
              deploying, managing, and scaling applications using Kubernetes.
              Join us to explore the forefront of technology and prepare for the
              dynamic landscape of tomorrow.
            </div>

            {/* <div
              style={{
                width: 238,
                height: 64,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 14,
                paddingBottom: 14,
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                background: "#FFD11A",
                borderRadius: 16,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#141414",
                  fontSize: 18,
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Explore Courses
              </div>
            </div> 
          </div>
          <img
            style={{ borderRadius: 24, width: 386, height: 386 }}
            src={Kubernetes}
            alt="Kubernetes"
          />
        </div>
      </div>*/}
      <FooterNeo />
    </>
  );
};

export default Courses;
