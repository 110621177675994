import emailjs from "@emailjs/browser";

export const sendConnection = async (data) => {
  const userDetails = {
    from_name: `${data?.firstName?.value} ${data?.lastName?.value}`,
    mobile: data?.mobile?.value,
    email: data?.email?.value,
  };

  emailjs
    .send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      userDetails,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (err) => {
        console.log("FAILED...", err);
      }
    );
};

export const sendConnectionContactUs = async (data) => {
  const userDetails = {
    from_name: `${data?.fullName?.value}`,
    mobile: data?.mobile?.value,
    email: data?.email?.value,
    remarks: data?.remarks?.value,
  };

  emailjs
    .send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID_CONTACT_US,
      userDetails,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (err) => {
        console.log("FAILED...", err);
      }
    );
};

// export async function postData(requestData) {
//   try {
//     await transporter.sendMail({
//       ...mailerOptions,
//       subject: "Request for new batch",
//       text: "Hey I am want to be part of your institution",
//       html: `<h1>Aspirant name: ${requestData?.firstName?.value} ${requestData?.lastName?.value}</h1>
//       <h3>Email: ${requestData?.email?.value}</h3>
//       <h3>Mobile Number: ${requestData?.mobile?.value}</h3>
//       `,
//     });

//     return { message: "Mail sent successfullly" };
//   } catch (e) {
//     console.log(e);
//     return { message: "Bad request" };
//   }
// }
