import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { useRecoilState } from "recoil";
import Services from "./pages/Services";
import "bootstrap/dist/css/bootstrap.min.css";
import NewBatches from "./pages/NewBatches";
import AboutUs from "./pages/AboutUs";
import ContacuUs from "./pages/ContactUs";
import Courses from "./pages/Courses";
import Register from "./Components/Modals/Register";
import React, { useState } from "react";
import { initialFormValues, registerSelector } from "./recoil/register";
import { sendConnection } from "./utils/mailerApi";

export const nameRegex = /^[A-Za-z ]+(?:\s[A-Za-z]+)*$/;

function App() {
  const [inProgress, setInProgress] = useState({ message: "", status: false });
  const [registerForm, setRegisterForm] = useRecoilState(registerSelector);

  const handleRegisterForm = (e) => {
    const { name, value, id } = e.target;
    let validateValue = value;

    if (id === "name" && validateValue?.length > 50) return;

    if (
      id === "name" &&
      name !== "email" &&
      validateValue &&
      !nameRegex.test(validateValue)
    )
      return;

    if (id === "number" && parseInt(validateValue) < 10000000000) {
      validateValue = parseInt(validateValue) || "";
    } else if (id === "number" && parseInt(validateValue) > 10000000000) {
      return;
    }

    setRegisterForm({
      ...registerForm,
      [name]: { value: validateValue, error: validateValue ? "" : "Required" },
    });
  };

  const handleClose = () => {
    setRegisterForm({ ...registerForm, show: !registerForm.show });
  };

  const handleSubmit = async () => {
    setInProgress(true);
    let validateValues = { ...registerForm };
    let formValid = true;

    Object.keys(registerForm).forEach((each) => {
      if (each !== "show" && each !== "message" && each !== "success") {
        validateValues[each] = validateValues[each].value
          ? validateValues[each]
          : { ...validateValues[each], error: "Required" };

        if (formValid && !validateValues[each].value) {
          formValid = false;
        }
      }
    });

    if (formValid) {
      try {
        await sendConnection(registerForm);
        setRegisterForm({
          ...registerForm,
          show: true,
          success: true,
          message:
            "We have received your request successfully. We will reach out to you.",
        });

        setTimeout(() => {
          setRegisterForm({ ...initialFormValues, show: false });
          setInProgress(false);
        }, 2000);
      } catch (e) {
        setInProgress(false);
        setRegisterForm({
          ...validateValues,
          message: "Please provide us valid credentials.",
          success: false,
        });
      }
    } else {
      setInProgress(false);
      setRegisterForm({ ...validateValues });
    }
  };

  return (
    <React.Fragment>
      <Register
        registerForm={registerForm}
        handleClose={handleClose}
        handleRegisterForm={handleRegisterForm}
        handleSubmit={handleSubmit}
        inProgress={inProgress}
      />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/new_batches" element={<NewBatches />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/contact_us" element={<ContacuUs />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
