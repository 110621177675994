import { Button } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { registerSelector } from "../../recoil/register";
import "../../styles/Navbar/nav-aboutus.css";

const AboutUsBanner = () => {
  const [registerForm, setRegisterForm] = useRecoilState(registerSelector);

  const handleClose = () => {
    setRegisterForm({ ...registerForm, show: !registerForm.show });
  };

  return (

    <div className="bg-image">
      <div className="aboutus-text col-lg-8 col-md-8 col-sm-12">        
          <p className="aboutus-tag pb-0 mb-0"> Discover Our Story:</p>
          <p className="aboutus-tag pb-0 mb-0"> Unveiling the</p>
          <p className="aboutus-tag pb-0 mb-0"> Vision and Values that Drive Us</p>
      </div>
    </div>


    // <div
    //   style={{
    //     width: "-webkit-fill-available",
    //     height: "400px",
    //     position: "relative",
    //     backgroundImage: "url('/home/TechFrame2.svg')",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "cover",
    //     flexDirection: "column",
    //     justifyContent: "flex-start",
    //     alignItems: "flex-start",
    //     display: "inline-flex",
    //     padding: "60px",
    //   }}
    //  >

    //   <div
    //     style={{
    //       flexDirection: "column",
    //       justifyContent: "flex-start",
    //       alignItems: "flex-start",
    //       gap: 24,
    //       display: "inline-flex",
    //     }}
    //   >
    //     <div
    //       style={{
    //         alignSelf: "stretch",
    //         color: "white",
    //         width: "50%",
    //         fontSize: 48,
    //         fontWeight: "700",
    //         wordWrap: "break-word",
    //       }}
    //     >
    //       Discover Our Story: Unveiling the Vision and Values that Drive Us
    //     </div>
    //   </div>

    //   <div
    //     style={{
    //       width: 100,
    //       height: 100,
    //       position: "fixed",
    //       alignSelf: "flex-end",
    //       top: 360,
    //       zIndex: 999999,
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 100,
    //         height: 100,
    //         left: 0,
    //         top: 0,
    //         position: "absolute",
    //         background: "#FFD119",
    //         borderRadius: 9999,
    //       }}
    //     />
    //     <Button
    //       style={{
    //         left: 6,
    //         top: 32,
    //         position: "absolute",
    //         color: "black",
    //         fontSize: 18,
    //         fontWeight: "500",
    //         backgroundColor: "transparent",
    //         border: "none",
    //       }}
    //       onClick={handleClose}
    //     >
    //       Register
    //     </Button>
    //   </div>
    // </div>
  );
};

export default AboutUsBanner;
