import NeoLogo from "../Public/Neoteric_Logo.svg";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { registerSelector } from "../recoil/register";
import React from 'react';
import { Container, Navbar, Nav} from "react-bootstrap";
import "../styles/Navbar/Navbar.css";

const NavBarWithRecoil = ({ pageName = "" }) => {
  const [registerForm, setRegisterForm] = useRecoilState(registerSelector);
  const router = useLocation();
  console.log(router);

  const handleClose = () => {
    setRegisterForm({ ...registerForm, show: !registerForm.show });
  };

  const handleRouteName = (path) => {
    return router.pathname === path; 
  }
// New code
  return (
          <Navbar className="Navbar-header" expand="lg">
        <Container>
          <Navbar.Brand href="/" className=""> <img style={{ width: 131,height: 58, }} src={NeoLogo} alt="NeoLogo"/> </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">            
            <Nav className="brand ms-auto">
              <Link className={handleRouteName("/services") ? "active-list-item" : "list-item"} to={"/services"} onClick={() => {window.scroll(0, 0, "smooth");}}>Services</Link>
              <Link className={handleRouteName("/courses/") ? "active-list-item" : "list-item"} to={"/courses"} onClick={() => {window.scroll(0, 0, "smooth");}}>Courses</Link>
              <Link className={handleRouteName("/new_batches") ? "active-list-item" : "list-item"} to={"/new_batches"} onClick={() => {window.scroll(0, 0, "smooth");}}>New batch</Link>
              <Link className={handleRouteName("/about_us/") ? "active-list-item" : "list-item"} to={"/about_us"} onClick={() => {window.scroll(0, 0, "smooth");}}>About Us</Link>
              <Link className={handleRouteName("/contact_us/") ? "active-list-item" : "list-item"} to={"/contact_us"} onClick={() => {window.scroll(0, 0, "smooth");}}>Contact Us</Link>
              
              
              {/* <Nav.Link href="/services" className={handleRouteName("/services") ? "active-list-item" : "list-item"}>Service</Nav.Link> */}
              {/* <Nav.Link href="/courses" className={handleRouteName("/courses/") ? "active-list-item" : "list-item"}>Courses</Nav.Link>  
              <Nav.Link href="/new_batches" className={handleRouteName("/new_batches") ? "active-list-item" : "list-item"}>New batch</Nav.Link>             
              <Nav.Link href="/about_us" className={handleRouteName("/about_us/") ? "active-list-item" : "list-item"}>About Us</Nav.Link> 
              <Nav.Link href="/contact_us" className={handleRouteName("/contact_us/") ? "active-list-item" : "list-item"}>Contact Us</Nav.Link>  */}
            </Nav>
            <Nav className="button ms-auto">
              <button onClick={handleClose} className="btn">Register</button>
            </Nav>
          </Navbar.Collapse>
          
        </Container>
      </Navbar>
  )
};

const NavBar = ({ pageName = "" }) => {
  return <NavBarWithRecoil pageName={pageName} />;  
};

export default NavBar;
