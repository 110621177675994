import FooterNeo from "../../Components/FooterNeo";
import NavBar from "../../Components/Navbar";
import "../../styles/Navbar/newbatches.css";

const NewBatches = () => {
  return (
    <>
      <NavBar pageName="NewBatch" />
      <div className="container mt-3">
        <div className="row col-12">
          <div className="heading justify-content-center">
            <h1 className="NewBatch-heading">New Batch</h1>
            <h6 className="NewBatch-text col-lg-8 col-md-6 col-sm-12">Join our latest batches and experience a fresh wave of learning! Dive into cutting-edge courses, engage with industry experts, and stay ahead with our innovative curriculum</h6>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row col-12">
          <table class="table table-hover mb-4">
            
              <tr class="table-tr">
                <th scope="col"> Name </th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Duration</th>
              </tr>
            
            <tbody>
              <tr className="table-td">
                <td scope="row"> Java Fullstack Hands-on Training </td>
                <td> 9th Feb, 2024 </td>
                <td> 08:00 AM - 05:00 PM </td>
                <td> 6 Months </td>
              </tr>
              <tr>
                <td scope="row"> Java Fullstack Hands-on Training </td>
                <td> 7th Mar, 2024 </td>
                <td> 08:00 AM - 05:00 PM </td>
                <td> 6 Months </td>
              </tr>
              <tr>
                <td scope="row"> Java Fullstack Hands-on Training </td>
                <td> 4th April, 2024 </td>
                <td> 08:00 AM - 05:00 PM </td>
                <td> 6 Months </td>
              </tr>
              <tr>
                <td scope="row"> Java Fullstack Hands-on Training </td>
                <td> 2nd May, 2024 </td>
                <td> 08:00 AM - 05:00 PM </td>
                <td> 6 Months </td>
              </tr>
              <tr>
                <td scope="row"> Java Fullstack Hands-on Training </td>
                <td> 6th June, 2024 </td>
                <td> 08:00 AM - 05:00 PM </td>
                <td> 6 Months </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>



      {/* <div className="NewBatch-heading"
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          background: "white",
          padding: "100px",
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            // left: 100,
            // top: 183,
            // position: "absolute",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 27,
            display: "inline-flex",
            marginBottom: "24px",
          }}
        >
          <div
            style={{
              width: 528,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 20,
                display: "inline-flex",
                marginLeft: 5,
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: 42,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 12,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    color: "black",
                    fontSize: 32,
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}
                >
                  New Batch
                </div>
              </div>
              <div
                style={{
                  width: 708,
                  // left: 100,
                  // top: 241,
                  // position: "absolute",
                  color: "#5D5D5D",
                  fontSize: 16,
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Join our latest batches and experience a fresh wave of learning!
                Dive into cutting-edge courses, engage with industry experts,
                and stay ahead with our innovative curriculum
              </div>
            </div>
          </div>
        </div>
        {/* <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead
            style={{
              borderRadius: 60,
              border: "1px #FFD119 solid",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <tr
              style={{
                background: "#FFD119",
                color: "black",
                height: 64,
                background: "#FFD119",
                borderRadius: 6,
                border: "1px #FFD119 solid",
                gap: 40,
              }}
            >
              <th style={{ width: 260, padding: "1rem" }}>Name</th>
              <th style={{ width: 120, padding: "1rem" }}>Date</th>
              <th style={{ width: 200, padding: "1rem" }}>Timings</th>
              <th style={{ width: 140, padding: "1rem" }}>Duration</th>
              <th style={{ width: 180, padding: "1rem" }}>Trainer</th>
              <th style={{ width: 178, padding: "1rem" }}>Register for Demo</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6].map((each) => (
              <tr key={each}
              style={{
                borderRadius: 60,
                border: "1px #FFD119 solid",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "20px 10px"
              }}>
                <td
                  style={{
                    border: "1px solid rgba(161, 161, 161, 0.18)",
                    padding: "8px",
                  }}
                >
                  Java Full Stack Hand-on Training
                </td>
                <td
                  style={{
                    border: "1px solid rgba(161, 161, 161, 0.18)",
                    padding: "8px",
                  }}
                >
                  1 Jan 2024
                </td>
                <td
                  style={{
                    border: "1px solid rgba(161, 161, 161, 0.18)",
                    padding: "8px",
                  }}
                >
                  12:00 PM - 1:30 PM
                </td>
                <td
                  style={{
                    border: "1px solid rgba(161, 161, 161, 0.18)",
                    padding: "8px",
                  }}
                >
                  90 Days
                </td>
                <td
                  style={{
                    border: "1px solid rgba(161, 161, 161, 0.18)",
                    padding: "8px",
                  }}
                >
                  Name of the trainer
                </td>
                <td
                  style={{
                    border: "1px solid rgba(161, 161, 161, 0.18)",
                    padding: "8px",
                  }}
                >
                  <span style={{ color: "#1A2F4C", fontWeight: "700" }}>
                    Register Now
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table> 

        <div
          style={{
            height: 64,
            background: "#FFD119",
            borderRadius: 6,
            border: "1px #FFD119 solid",
            justifyContent: "space-between",
            alignItems: "center",
            display: "inline-flex",
            margin: 5,
          }}
        >
          <div
            style={{
              width: 260,
              height: 48,
              paddingLeft: 22,
              paddingRight: 22,
              paddingTop: 12,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                alignSelf: "stretch",
                color: "black",
                fontSize: 16,
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Name
            </div>
          </div>
          <div
            style={{
              width: 120,
              alignSelf: "stretch",
              paddingTop: 8,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                color: "black",
                fontSize: 16,
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Date
            </div>
          </div>
          <div
            style={{
              width: 200,
              alignSelf: "stretch",
              paddingLeft: 18,
              paddingRight: 18,
              paddingTop: 8,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                color: "black",
                fontSize: 16,
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Timings
            </div>
          </div>
          <div
            style={{
              width: 160,
              alignSelf: "stretch",
              paddingLeft: 18,
              paddingRight: 18,
              paddingTop: 8,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                color: "black",
                fontSize: 16,
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Duration
            </div>
          </div>
          {/* <div
            style={{
              width: 180,
              alignSelf: "stretch",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 8,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                color: "black",
                fontSize: 16,
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Trainer
            </div>
          </div> */}
          {/* <div
            style={{
              width: 178,
              alignSelf: "stretch",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 8,
              paddingBottom: 8,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "1 1 0",
                color: "black",
                fontSize: 16,
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Register for Demo
            </div>
          </div> 
        </div>
        {[
          "9th Feb, 2024",
          "7th Mar, 2024",
          "4th April, 2024",
          "2nd May, 2024",
          "6th June, 2024",
        ].map((each) => (
          <div
            style={{
              alignSelf: "stretch",
              height: 64,
              borderRadius: 6,
              border: "1px rgba(161, 161, 161, 0.18) solid",
              justifyContent: "space-between",
              alignItems: "center",
              display: "inline-flex",
              margin: 5,
            }}
            key={`${each?.toString()}`}
          >
            <div
              style={{
                width: 260,
                height: 48,
                paddingLeft: 20,
                paddingRight: 20,
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  alignSelf: "stretch",
                  color: "black",
                  fontSize: 16,
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Java Fullstack Hands-on Training
              </div>
            </div>
            <div
              style={{
                width: 140,
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "black",
                  fontSize: 16,
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {each}
              </div>
            </div>
            <div
              style={{
                width: 200,
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "black",
                  fontSize: 16,

                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                08:00 AM - 05:00 PM
              </div>
            </div>
            <div
              style={{
                width: 140,
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "black",
                  fontSize: 16,

                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                6 Months
              </div>
            </div>
            {/* <div
              style={{
                width: 180,
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "black",
                  fontSize: 16,

                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Name of the trainer
              </div>
            </div> */}
            {/* <div
              style={{
                flex: "1 1 0",
                alignSelf: "stretch",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 8,
                paddingBottom: 8,
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#1A2F4C",
                  fontSize: 16,

                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Register Now
              </div>
            </div> 
          </div> 
        ))}
      </div>*/}
      <FooterNeo />
    </>
  );
};

export default NewBatches;
