import "../../styles/Navbar/nav-aboutus.css";

const AboutUsDetails = () => {
  return (

    <div className="container ">
      <div className="row col-12">
        <div className="heading justify-content-center">
          <h1 className="AboutUs-heading">About Us</h1>
          <h6 className="AboutUs-text col-lg-12 col-md-12 col-sm-12">Welcome to neoteric METHOD, your top choice for immersive Java training
            and workshops. Our unwavering commitment is to equip individuals with
            cutting-edge skills, promoting innovation and nurturing the future
            leaders of the digital landscape. Immerse yourself in a transformative
            learning experience where expertise merges seamlessly with the neoteric.
            Elevate your potential with us.</h6>
        </div>
      </div>
    </div>


    // <div
    //   style={{
    //     width: "-webkit-fill-available",
    //     height: "100%",
    //     flexDirection: "column",
    //     justifyContent: "flex-start",
    //     alignItems: "flex-start",
    //     gap: 16,
    //     display: "inline-flex",
    //     padding: "20px 60px 20px 60px",
    //     margin: "20px 40px 20px 40px",
    //   }}
    // >
    //   <div
    //     style={{
    //       flexDirection: "column",
    //       justifyContent: "flex-start",
    //       alignItems: "flex-start",
    //       gap: 27,
    //       display: "flex",
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: 528,
    //         justifyContent: "flex-start",
    //         alignItems: "flex-start",
    //         gap: 24,
    //         display: "inline-flex",
    //       }}
    //     >
    //       <div
    //         style={{
    //           flex: "1 1 0",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           alignItems: "flex-start",
    //           gap: 8,
    //           display: "inline-flex",
    //         }}
    //       >
    //         <div
    //           style={{
    //             alignSelf: "stretch",
    //             height: 42,
    //             flexDirection: "column",
    //             justifyContent: "flex-start",
    //             alignItems: "flex-start",
    //             gap: 12,
    //             display: "flex",
    //           }}
    //         >
    //           <div
    //             style={{
    //               alignSelf: "stretch",
    //               color: "black",
    //               fontSize: 32,
    //               fontWeight: "700",
    //               wordWrap: "break-word",
    //             }}
    //           >
    //             About Us
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       width: 1209,
    //       color: "#5D5D5D",
    //       fontSize: 16,
    //       fontWeight: "500",
    //       wordWrap: "break-word",
    //     }}
    //   >
    //     Welcome to neoteric METHOD, your top choice for immersive Java training
    //     and workshops. Our unwavering commitment is to equip individuals with
    //     cutting-edge skills, promoting innovation and nurturing the future
    //     leaders of the digital landscape. Immerse yourself in a transformative
    //     learning experience where expertise merges seamlessly with the neoteric.
    //     Elevate your potential with us.
    //   </div>
    // </div>
  );
};

export default AboutUsDetails;
