import Rectangle2 from "../../Public/Rectangle2.svg";
import ProjectTraining from "../../Public/ProjectTraining.svg";
import Internship from "../../Public/Internship.svg";
import JavaFS from "../../Public/JavaFS.svg";
import SpecialBatches from "../../Public/SpecialBatches.svg";
import CorporateTraining from "../../Public/CorporateTraining.svg";
import NavBar from "../../Components/Navbar";
import FooterNeo from "../../Components/FooterNeo";
import VectorContent from "../../Components/Home/VectorConent";
import { Button } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { registerSelector } from "../../recoil/register";
import "../../styles/services/services.css";
import bannerStyles from "../../styles/services/Banner.module.scss";

const data = {
  mainHeading: "neoteric METHOD",
  content: `Embark on a transformative educational journey in software at neoteric METHOD, 
    where our emblem symbolizes forward-thinking learning. Immerse yourself in
     cutting-edge technologies, AI applications, and top-tier software solutions. 
     Participate in hands-on training, receive expert guidance, and confidently 
     shape the future of technology under our corporate mentorship.`,
};

const RegisterButton = () => {
  const [registerForm, setRegisterForm] = useRecoilState(registerSelector);

  const handleFunction = () => {
    setRegisterForm({ ...registerForm, show: !registerForm.show });
  };

  return <div className="service-text">
    <div className="service-bg">
      <Button
        className={bannerStyles.register_button}
        onClick={handleFunction}>
        Register
      </Button>
    </div>
  </div>
}

const Services = () => {
  const [registerForm, setRegisterForm] = useRecoilState(registerSelector);

  const handleClose = () => {
    setRegisterForm({ ...registerForm, show: !registerForm.show });
  };

  return (
    <>
      <NavBar pageName={"Services"} />
      <div style={{ position: "relative" }}>
        <RegisterButton />
      </div>
      <div className="bg-image">
        <div className="blog-1 col-lg-6 col-md-6">
          <h1 className="blog-heading">Crafting Your IT Success Story</h1>
          <p className="blog-text">Experience our premium services at neoteric METHOD, where
            innovation meets expertise, sculpting personalized solutions to
            shape your journey towards unparalleled success in the dynamic
            world of information technology</p>
        </div>
      </div>

      <VectorContent data={data} />
      {/* <div className="blog-3">
        <div className="container">
          <div className="d-flex flex-xs-column flex-md-row blog-3 col-12 p-2">
            <div className="d-none d-md-flex col-6">
              <img className="img" src={Rectangle2} alt="Rectangle" />
            </div>
            <div className="content col-6">
              <h1 className="heading">Classroom Training</h1>
              <div className="col-6 d-flex d-md-none">
                <img className="img" src={Rectangle2} alt="Rectangle" />
              </div>
              <p className="p-tag">Embark on an enriching journey with our Classroom Training
                program. Immerse yourself in a vibrant educational setting, guided
                by industry experts. Acquire practical insights and cultivate
                collaborative knowledge-sharing. Enhance your skills through
                hands-on training, gearing up for success in the constantly
                evolving professional landscape</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="blog-3">
        <div className="container">
          <div className="d-flex blog-3 col-12 p-2">
            <div className="col-6">
              <img className="img" src={Rectangle2} alt="Rectangle2" />
            </div>
            <div className="content col-6">
              <h1 className="heading mb-3">Classroom Training</h1>
              <p className="p-tag">Embark on an enriching journey with our Classroom Training
                program. Immerse yourself in a vibrant educational setting, guided
                by industry experts. Acquire practical insights and cultivate
                collaborative knowledge-sharing. Enhance your skills through
                hands-on training, gearing up for success in the constantly
                evolving professional landscape</p>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-4">
        <div className="container">
          <div className="d-flex col-12 p-2">
            <div className="content col-6">
              <h1 className="heading">Project Training</h1>
              <p className="p-tag">Experience top-notch training with live projects, offering
                real-time exposure for unparalleled learning. Our Java project
                training goes beyond theory, focusing on practical application in
                Java-based projects. Participants gain hands-on experience, honing
                problem-solving, teamwork, and project management skills,
                equipping them for success in Java development and related
                professional challenges.</p>
            </div>
            <div className="col-6">
              <img className="img" src={ProjectTraining} alt="Project Training" />
            </div>
          </div>
        </div>
      </div>

      <div className="blog-3">
        <div className="container">
          <div className="d-flex blog-3 col-12 p-2">
            <div className="col-6">
              <img className="img" src={Internship} alt="Internship" />
            </div>
            <div className="content col-6">
              <h1 className="heading mb-3">Internships</h1>
              <p className="p-tag">Unleash your potential through our immersive internship program, where you`ll acquire hands-on experience, mentorship, and exposure to real-world projects. Cultivate essential skills, broaden your network, and pave the way for a flourishing career. Join us in crafting the future during your internship—your chance, your opportunity, your pathway to success. Apply now and embrace the exciting journey that lies ahead!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-4">
        <div className="container">
          <div className="d-flex blog-4 col-12 p-2">
            <div className="content col-6">
              <h1 className="heading mb-3">Java Fullstack</h1>
              <p className="p-tag">Embrace excellence in Java Fullstack development with Neoteric Methods, harmoniously integrating both frontend and backend technologies. Enhance user experiences, optimize performance, and deliver robust solutions tailored for a dynamic and modern development landscape.</p>
            </div>
            <div className="col-6">
              <img className="img" src={JavaFS} alt="JavaFS" />
            </div>
          </div>
        </div>
      </div>

      <div className="blog-3">
        <div className="container">
          <div className="d-flex blog-3 col-12 p-2">
            <div className="col-6">
              <img className="img" src={SpecialBatches} alt="Special Batches" />
            </div>
            <div className="content col-6">
              <h1 className="heading mb-3">Special Batches</h1>
              <p className="p-tag">Embark on an innovative journey with Neoteric Method’s Exclusive Special Batches! Boost your expertise in Java Full Stack development with individualized coaching, practical projects, and a tailor-made curriculum. Become part of our professional community, unlock your full potential through our specialized approach, and stand out in the ever-evolving tech landscape. Enroll now to sculpt your future with Neoteric Method’s steadfast commitment to excellence and personalized learning experiences designed for corporate success.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-4">
        <div className="container">
          <div className="d-flex blog-4 col-12 p-2">
            <div className="content col-6">
              <h1 className="heading mb-3">Corporate Training</h1>
              <p className="p-tag">Corporate training involves a methodical approach where organizations offer employees structured learning opportunities to augment their skills, knowledge, and competencies. Geared towards enhancing both individual and collective performance, corporate training is designed to align with organizational goals, promoting professional development and adaptability in the dynamic business environment to ensure sustained success.</p>
            </div>
            <div className="col-6">
              <img className="img" src={CorporateTraining} alt="Corporate Training" />
            </div>
          </div>
        </div>
      </div>

      <div className="choices">
        <div className="row mr-0 pr-0 col-12 p-3 justify-content-center">
          <div className="row justify-content-center col-lg-8 col-md-8 col-sm-12">
            <p className="choices-p-tag">Join sessions at our dedicated venue, offering an alternative
              environment for an optimal learning experience.</p>
            <button className="choices-button">Offline sessions</button>
          </div>
          <div className="row justify-content-center col-lg-8 col-md-8 col-sm-12">
            <p className="choices-p-tag">Convenient on-site sessions conducted at your office.</p>
            <button className="choices-button">On-site sessions </button>
            <h1 className="choices-headng">We provide two choices for sessions</h1>
          </div>
        </div>
      </div>
      <FooterNeo />
    </>
  );
};

export default Services;
