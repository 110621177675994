import Facebook from "../Public/Footer/facebook.svg";
import Instagram_vector from "../Public/Footer/Instagram_svg.svg";
import Twitter_vector from "../Public/Footer/twitter_vector.svg";
import linkedin_vector from "../Public/Footer/Vectorlinkedin.svg";
import NeoLogo from "../Public/Footer/NeotericFooter.svg";
import React from "react";
import CopyrithIcon from "../Public/Footer/copyright.svg";
import { Link } from "react-router-dom";
import "../styles/home/footer.css"

const CopyRight = () => (
  <div className="footer-container col-sm-12">

    <div className="footer-text pb-1 mb-0">
      <p>Copyright<span><img width={17} height={17} src={CopyrithIcon} alt="CopyrithIcon" />neoteric METHOD. All Rights Reserved</span></p>

    </div>
  </div>
);

const FooterNeo = () => {
  return (
    <React.Fragment>
      <div className="container-fluid footer-neo ">
        <div className="row col-12">
          <div className="col-lg-5 col-md-5 col-sm-6">
            <img className="footer-neo-img"
              src={NeoLogo} alt="NeoLogo" />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <p className="social-media-heading mb-1 pb-2"> Follow Us On</p>
            <p className="social-media-text mb-1 pb-1">
              <Link to="https://www.facebook.com/Neotericmethods" target="_blank"><span> <img className="social-media-img" src={Facebook} alt="Facebook" />
              </span> Facebook</Link>
            </p>
            <p className="social-media-text mb-1 pb-1">
              <Link to="https://twitter.com/NMethod93321" target="_blank"><span><img className="social-media-img" src={Twitter_vector} alt="Twitter" />
              </span> Twitter</Link>
            </p>
            <p
              className="social-media-text mb-1 pb-1">
              <Link to="https://www.instagram.com/neotericmethods/" target="_blank"><span> <img className="social-media-img" src={Instagram_vector} alt="Instagram" />
              </span> Instagram</Link>
            </p>
            <p className="social-media-text mb-1 pb-1">
              <Link to="https://www.linkedin.com/in/neoteric-method-5185102a3/" target="_blank"><span> <img className="social-media-img" src={linkedin_vector} alt="linkedin" />
              </span>Linkedin</Link>
            </p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 ">
            <p className="social-media-heading mb-1 pb-2">Quick Links</p>
            <p className="social-media-text mb-1 pb-1"><Link to={"/services"}
              onClick={() => {
                window.scroll(0, 0, "smooth");
              }}>Services</Link></p>
            <p className="social-media-text mb-1 pb-1"><Link to={"/courses"}
              onClick={() => {
                window.scroll(0, 0, "smooth");
              }}>Courses</Link></p>
            <p className="social-media-text mb-1 pb-1"><Link to={"/about_us"}
              onClick={() => {
                window.scroll(0, 0, "smooth");
              }}>About Us</Link></p>
            <p className="social-media-text mb-1 pb-1"><Link to={"/contact_us"}
              onClick={() => {
                window.scroll(0, 0, "smooth");
              }}>Contact Us</Link></p>
          </div>
        </div>
      </div>


      {/* <div className="row footer-neo ">
        
        <div className="footer-content col-lg-4 col-md-4 col-sm-12">
          <div className="footer-content-line">
           <div className="social-media-heading">
              Follow Us On
            </div>
            <div className="social-media">
              <img className="social-media-img" src={Facebook} alt="Facebook" />
              <div className="social-media-text">
                Facebook
              </div>
            </div>
            <div className="social-media">
              <img className="social-media-img" src={Twitter_vector} alt="Twitter" />
              <div className="social-media-text">
                Twitter
              </div>
            </div>
            <div className="social-media">
              <img className="social-media-img" src={Instagram_vector}
                alt="Instagram"
              />
              <div className="social-media-text">
                Instagram
              </div>
            </div>
          </div>
          <div className="footer-content-line">
            <div className="social-media-heading">
              Quick Links
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  border: "static 2px #fff",
                  borderRadius: "50%",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#fff",
                }}
              ></div>
              <Link
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  wordWrap: "break-word",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={"/services"}
                onClick={() => {
                  window.scroll(0, 0, "smooth");
                }}
              >
                Services
              </Link>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  border: "static 2px #fff",
                  borderRadius: "50%",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#fff",
                }}
              ></div>
              <Link
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  wordWrap: "break-word",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={"/courses"}
                onClick={() => {
                  window.scroll(0, 0, "smooth");
                }}
              >
                Courses
              </Link>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  border: "static 2px #fff",
                  borderRadius: "50%",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#fff",
                }}
              ></div>
              <Link
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  wordWrap: "break-word",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={"/about_us"}
                onClick={() => {
                  window.scroll(0, 0, "smooth");
                }}
              >
                About Us
              </Link>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  border: "static 2px #fff",
                  borderRadius: "50%",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#fff",
                }}
              ></div>
              <Link
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  wordWrap: "break-word",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={"/contact_us"}
                onClick={() => {
                  window.scroll(0, 0, "smooth");
                }}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <CopyRight />
    </React.Fragment>
  );
};

export default FooterNeo;
