import VectorLeft from "../../Public/Vector.svg";
import VectorRight from "../../Public/VectorRight.svg";
import "../../styles/home/vectorconent.css";

export const Frame5Wrapper = ({ children }) => {
  return (
    <div className="bg-img">
      {children}
    </div>
  );
};

const VectorContent = ({ data = {} }) => (
  <Frame5Wrapper>
    <div className="container blog-2 col-lg-12 col-md-12 col-sm-12">
      <div className="Vector-left">
        <img className="Vector-img" src={VectorLeft} height={100} width={100} alt="Vector left icon" />
      </div>
      <div className="headng">
        {data?.mainHeading || null}
      </div>
      <div className="content">
        {data?.content || null}
      </div>
      <div className="Vector-right">
        <img className="Vector-img"
          src={VectorRight}
          alt="vector right icon"
        />
      </div>
    </div>
    <div
      style={{
        paddingTop: 24,
        paddingBottom: 24,
        //
        //
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        display: "inline-flex",
      }}
    >
      <div style={{ width: 99, height: 77, background: "transparent" }}></div>
    </div>
  </Frame5Wrapper>
);

export default VectorContent;
