import React from "react";
import NavBar from "../../Components/Navbar";
import AboutUsBanner from "../../Components/aboutUs/AboutUsBanner";
import AboutUsDetails from "../../Components/aboutUs/AboutUsDetails";
import AboutUsContent from "../../Components/aboutUs/AboutUsContent";
import FooterNeo from "../../Components/FooterNeo";

const AboutUs = () => {
  return (
    <React.Fragment>
      <NavBar pageName="AboutUs" />
      <AboutUsBanner />
      <AboutUsDetails />
      <AboutUsContent />
      <FooterNeo />
    </React.Fragment>
  );
};

export default AboutUs;
