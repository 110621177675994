import React from "react";
import NavBar from "../../Components/Navbar";
import FooterNeo from "../../Components/FooterNeo";
import ContactUsForm from "../../Components/contactUs/ContactUsFrom";

const ContacuUs = () => {
  return (
    <React.Fragment>
      <NavBar pageName="ContactUs" />
      <ContactUsForm />
      <FooterNeo />
    </React.Fragment>
  );
};

export default ContacuUs;
